/* failed tip */
.failed_tip_wrap {
  padding-bottom: 32px;
  @apply text-center;
}
.failed_tip_icon {
  margin: 40px auto 16px;
}
.failed_tip > h5 {
  font-size: 18px;
}
.failed_tip > p {
  font-size: 14px;
  width: 420px;
  margin: 8px auto 0;
}

.outbound_wrap {
  @apply bg-white sm:min-h-420;
}

/* prepared */
.outbound_amount {
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: 24px;
}
.outbound_descriptions {
  margin-top: 32px;
  margin-bottom: 40px;
}
.outbound_descriptions > p,
.outbound_footer > p {
  margin-bottom: 8px;
}
.outbound_footer {
  margin-top: 32px;
  padding-bottom: 32px;
  @apply text-center;
}

/* pending */
.pending {
  width: 420px;
  margin: 32px auto;
}
.pending > h4 {
  margin-bottom: 8px;
}
.pending > p {
  margin-bottom: 32px;
}

/* precessing */
.processing_info {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 24px;
}
.processing_info  > p {
  margin: 6px auto 32px;
}

/* done */
.outbound_done_icon > svg{
  margin: 24px auto 16px;
}

/* outbound info */
.outbound_info {
  padding: 32px 24px;
}
.outbound_info_table {
  margin-top: 32px;
}
.outbound_info_table_item {
  font-size: 14px;
  margin-top: 16px;
  @apply flex justify-between;
}
.outbound_info_table_item + hr {
  margin-top: 8px;
}
.outbound_info_address {
  width: 248px;
  margin: 0 auto;
  word-wrap: break-word;
  @apply text-center;
}
.outbound_info_address > div {
  background-color: rgba(147, 210, 253, 0.16);
  padding: 10px;
  margin-bottom: 10px;
  @apply inline-block rounded-full;
}
.outbound_info_arrow {
  margin: 24px auto;
  @apply text-center;
}

@media screen and (max-width: 599px) {
  .failed_tip_wrap,
  .pending_wrap {
    height: 56vh;
    padding-left: 16px;
    padding-right: 16px;
    @apply flex justify-center items-center;
  }
  .failed_tip > p {
    width: 100%;
  }

  .outbound_amount {
    margin-top: 0;
  }
  .outbound_footer {
    @apply text-left;
  }
  .pending {
    width: 100%;
    padding: 0 16px;
  }
  .outbound_info,
  .processing_info {
    padding-left: 16px;
    padding-right: 16px;
  }
}

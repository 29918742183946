@tailwind base;
@tailwind components;
@tailwind utilities;

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}
th,
td {
  font-weight: normal;
}

html,
body {
  min-height: 100%;
  min-height: 100vh;
  font-size: 14px;
  line-height: 1.43;
}

button:focus {
  outline: none;
}

.sm-input {
  height: 38px;
}
.md-input {
  height: 48px;
}
.lg-input {
  height: 52px;
}

.btn {
  @apply px-lg py-md rounded-6 text-center text-16 font-semibold;
}
.btn:disabled {
  @apply opacity-50;
}
.btn.btn-sm {
  @apply h-38 py-0 leading-38 rounded-8 text-center flex cursor-pointer justify-center items-center px-12 text-14 font-normal;
}
.btn.btn-md {
  @apply h-48 py-0 leading-38 rounded-8 text-center flex cursor-pointer justify-center items-center px-12;
}
.btn.btn-lg {
  @apply h-58 py-0 leading-38 rounded-8 text-center flex cursor-pointer justify-center items-center px-12;
}
.btn.btn-primary {
  @apply bg-blue text-white shadow-sm;
}
.btn.btn-secondary {
  @apply text-blue bg-white shadow-md border border-grey-shallow;
}
.btn-default {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  @apply bg-white text-blue;
}
.btn-link {
  @apply text-blue;
}

input {
  background: none;
  outline: none;
}

input:disabled {
  cursor: not-allowed;
  color: #7b849b;
}

.main_container {
  @apply min-h-screen sm:flex sm:flex-col sm:items-center;
}
.bg-image {
  background-image: linear-gradient(to bottom, #021e3f, #002a67 96%);
}
@media (min-width: 601px) {
  .main_container {
    background-image: linear-gradient(to bottom, #021e3f, #002a67 96%);
  }
}
.base_card {
  @apply container min-h-screen sm:min-h-600 sm:h-auto overflow-hidden flex flex-col sm:rounded-16;
}

.checkout_card {
  @apply base_card bg-white;
}

.page_card {
  @apply base_card bg-grey-lightBgColor;
}

.confirming_card {
  @apply bg-white sm:rounded-16;
}

@media screen and (max-width: 599px) {
  html,
  body {
    background: none;
    min-height: 100%;
    min-height: 100vh;
    min-width: 280px;
  }
  /*
  .outbound_address .rc-select,
  .outbound_address .rc-select-dropdown {
    transform: scale(0.7);
  }
  */
}

.spin-loading {
  transform: translateZ(0) scale(0.31);
  backface-visibility: hidden;
  -webkit-animation: spinnerLoading 1s linear infinite;
  animation: spinnerLoading 1s linear infinite;
}
@keyframes spinnerLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-size: 14px;
  padding-bottom: 8px;
}

.input-group.error .form-input {
  @apply border-red;
}

.input-group.error label {
  @apply text-red;
}

.form-input {
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  @apply border-grey-lightBorderColor;
}

.input {
  position: relative;
}
.input[data-suffix] input {
  padding-right: 22px;
}
.input[data-suffix]::before {
  position: absolute;
  top: 2px;
  right: 16px;
  line-height: 2;
  font-size: 14px;
  color: #97a5b6;
  content: attr(data-suffix);
}
.input .slip {
  max-width: 88px;
}

.alert {
  @apply flex flex-row items-center p-md rounded-8 text-16 font-semibold;
}

.alert--error {
  @apply text-error bg-red-light;
}

.arrow-icon {
  border: solid #1199fa;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-top: -3px;
}
.arrow-icon-up {
  transform: rotate(-135deg) translate(-4px);
  -webkit-transform: rotate(-135deg) translate(-4px);
}
.arrow-icon-down {
  transform: rotate(45deg) translate(-4px);
  -webkit-transform: rotate(45deg) translate(-4px);
}
.clear-pointer-events {
  pointer-events: none;
}

.transition-container {
  position: relative;
  @apply sm:shadow-2xl;
}

.transition-container:after {
  display: block;
  position: absolute;
  width: 0;
  left: 0;
  bottom: 0;
  border: 3px solid transparent;
  content: '';
}
.page-loading .transition-container:after {
  border: 3px solid #1199fa;
}
.page-loading .transition-container:after {
  animation: pageLoading 1s linear infinite forwards;
}

@keyframes pageLoading {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 100%;
    height: 0;
  }
}

.outbound_address .rc-select-selection--single {
  padding-right: 16px !important;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-size: 14px;
  @apply border-grey-lightBorderColor !important;
}

.outbound_address .rc-select-selection--single {
  height: 46px !important;
  padding-top: 8px;
}

.outbound_address .rc-select-arrow {
  top: 9px !important;
}

apple-pay-button {
  width: 100%;
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 48px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

@import url('./component/web3.css');
@import url('./pages/onchain-outbound.css');

.slip {
  @apply px-12 py-6 border border-grey-lightBorderColor text-grey rounded-16;
}

.slip.focus, .slip:hover, .slip:focus {
  @apply border-blue;
}

.btn-slip {
  @apply slip cursor-pointer;
}

.btn-slip.active {
  @apply bg-blue-light border-blue;
}